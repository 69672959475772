<template>
    <div class="select-dropdown" @focusout="$event => toggleShowContent($event, false)" tabindex="0">
        <div class="faux-select" @click="$event => toggleShowContent($event, true)">
            <div>{{ placeholder }}</div>
            <div class="dropdown"><i class="fas fa-chevron-down"></i></div>
        </div>
        <div class="select-dropdown-content" v-show="showContent">
            <div><i class="fas fa-chevron-up close" @click="$event => toggleShowContent($event, false)"></i></div>
            <slot class="select-dropdown-slot"></slot>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        placeholder: {
            type: String,
            required: false,
            default: 'Select'
        }
    },
    data() {
        return { showContent: false }
    },
    methods: {
        toggleShowContent($event, toggleOn) {
            if ($event?.relatedTarget?.id?.substring(0, 9) !== 'box-check') {
                this.showContent = toggleOn;
            }
        },
    }
}
</script>
<style scoped lang="scss">
@import "bootstrap/scss/forms";
.select-dropdown {
    position: relative;
    .dropdown {
        position: absolute;
        right: 8px;
        top: 6px;
    }
}

.select-dropdown-content {
    background-color: white;
    box-sizing: border-box;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.35);
    border: 1px solid #dbdbdb;
    position: absolute;
    top: 5px;
    left: 0;
    z-index: 999;
    font-size: 0.9375rem;
    min-width: 300px;
    min-height: 30px;
}

.faux-select {
    @extend .form-control;
    background-color: white;
    color: black;    
    height: 33px;
    padding-top: 4px;
}

.close {
    top: 7px;
    right: 7px;
    position: absolute;
    font-size: 0.9375rem;
    color: black !important;
    z-index: 1000;
}
</style>